import React from 'react';
import { Avatar, List } from 'antd';

function NotificationComponent({ notification }) {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar className="delivery-notification-icon" />}
        title={notification.message}
        description={notification.sentAt}
      />
    </List.Item>
  );
}

export default NotificationComponent;
