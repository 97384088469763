import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DELIVERY_APP_ROUTES } from 'shared/constants';
import NavigationButton from './NavigationButton';

export default function NavigationPanel() {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(history?.location?.pathname);

  history.listen(location => {
    setSelectedTab(location?.pathname);
  });

  return (
    <ul className="nav header-tab-selection">
      <NavigationButton
        to={DELIVERY_APP_ROUTES.DASHBOARD}
        name={t('delivery.navigationButton.dashboard')}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      <NavigationButton
        to={DELIVERY_APP_ROUTES.DELIVERY_DETAILS}
        name={t('delivery.navigationButton.deliveries')}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      <NavigationButton
        to={DELIVERY_APP_ROUTES.INVOICES}
        name={t('delivery.navigationButton.invoices')}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
      <NavigationButton
        to={DELIVERY_APP_ROUTES.NOTIFICATIONS}
        name={t('delivery.navigationButton.notifications')}
        setSelectedTab={setSelectedTab}
        selectedTab={selectedTab}
      />
    </ul>
  );
}
