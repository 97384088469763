import React from 'react';
import { Row, Col, Button, Carousel } from 'antd';
import { startCase, toLower, capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';

import PartialQuantityView from 'components/PartialQuantityView';
import { SPLIT_CODE_VALUE } from 'shared/constants';

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#e4e4e4'
};
const ProductView = ({ esyscoUserType, productInfo, closeProductView }) => {
  const {
    originalPrice,
    orderQty,
    originalOrderQty,
    isPartial,
    itemAmount,
    unitOfMeasure,
    productDetails,
    splitCode
  } = productInfo;
  const { productId, name, description, packSize, images } = productDetails;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Col span={10} className="delivery-invoice">
        <div>
          <Row>
            <Col span={24}>
              <Button type="link btn-invoice-link" onClick={closeProductView}>
                <span className="icon-back"></span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Carousel>
                {images.map(each => (
                  <div>
                    <img className="carousel-img" key={each} style={contentStyle} src={each} alt="product" />
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
          <Row className="deliverymap-items-header">
            <Col span={24} className="deliverymap-item">
              <p className="deliverymap-item-number">{`${productId}`}</p>
              <h1 className="deliverymap-item-h1">{`${startCase(toLower(name))}`}</h1>
            </Col>
          </Row>
          {esyscoUserType ? (
            <Row className="delivery-info-box">
              <Col span={8} className="delivery-info-box-one">
                <p>{t('delivery.product.unitCost')}</p>
                <h1 className="delivery-h1">{`$${originalPrice?.toFixed(2)}`}</h1>
              </Col>
              <Col span={8} className="delivery-info-box-two">
                <p>{t('delivery.product.quantity')}</p>
                {isPartial ? (
                  <PartialQuantityView
                    orderQty={orderQty}
                    originalOrderQty={originalOrderQty}
                    unitOfMeasure={unitOfMeasure}
                  />
                ) : (
                  <h1 className="delivery-h1">{`${orderQty} ${unitOfMeasure}`}</h1>
                )}
              </Col>
              <Col span={8} className="delivery-info-box-three">
                <p>{t('delivery.product.total')}</p>
                <h1 className="delivery-h1">{`$${itemAmount?.toFixed(2)}`}</h1>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={24}>
              <p>{t('delivery.product.description')}</p>
              <h1 className="deliverymap-description">{`${capitalize(description?.toLowerCase())}`}</h1>
            </Col>
          </Row>
          {!esyscoUserType && (
            <Row>
              <Col span={24}>
                <p>{t('delivery.product.quantity')}</p>
                {isPartial ? (
                  <PartialQuantityView
                    orderQty={orderQty}
                    originalOrderQty={originalOrderQty}
                    unitOfMeasure={unitOfMeasure}
                  />
                ) : (
                  <h1 className="deliverymap-description">{`${orderQty} ${unitOfMeasure}`}</h1>
                )}
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <p>{t('delivery.product.unitSize')}</p>
              <h1 className="deliverymap-description">
                {`${splitCode === SPLIT_CODE_VALUE ? 1 : packSize?.pack} x ${toLower(packSize?.size)}`}
              </h1>
            </Col>
            <Col span={24}>
              <hr className="devider" />
            </Col>
          </Row>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ProductView;
