import React from 'react';
import { Row, Col, Button, Input } from 'antd';
import AppLoader from 'components/AppLoader';
import { useTranslation } from 'react-i18next';
import NoDeliveryComponent from '../../../components/NoDeliveryComponent';
import CustomerList from './CustomerList';

function DashboardPageComponent({
  customersWithDeliveries,
  selectNewCustomer,
  isDeliveryDetailsRefreshing,
  refreshDeliveryDetails,
  timeSinceLastUpdate,
  isInitialDeliveryDetailsFetchUnderway,
  hasNoTodaysDeliveries,
  isInitialDeliveryDetailsCompleted,
  setSearchState,
  deliveryLength
}) {
  const onSearch = event => {
    setSearchState(event.target.value);
  };

  const { t } = useTranslation();
  if (isInitialDeliveryDetailsFetchUnderway) return <AppLoader />;
  if (hasNoTodaysDeliveries) return <NoDeliveryComponent />;
  if (isInitialDeliveryDetailsCompleted)
    return (
      <React.Fragment>
        <Row className="section-1">
          <Col span={24} className="delivery-title-inner">
            <div>
              <span className="truck"></span>
              <h1 className="delivery-h1">
                {`${t('delivery.dashboard.youHave')} `}
                <span className="delivery-number">
                  {`${deliveryLength} ${
                    deliveryLength > 1
                      ? ` ${t('delivery.dashboard.deliveries')} `
                      : ` ${t('delivery.dashboard.delivery')} `
                  }`}
                </span>
                {t('delivery.dashboard.today')}
              </h1>
              <p>{t('delivery.dashboard.moreDetailsMessage')}</p>
            </div>
          </Col>
          <Col span={24}>
            <div className="delivery-list-last-update">
              <span className="last-update-text">
                {timeSinceLastUpdate < 60
                  ? `${t('delivery.dashboard.lastUpdated')} ${timeSinceLastUpdate} ${t(
                      'delivery.dashboard.secondsAgo'
                    )}`
                  : `${t('delivery.dashboard.lastUpdated')} ${Math.floor(timeSinceLastUpdate / 60)} ${
                      Math.floor(timeSinceLastUpdate / 60) > 1
                        ? `${t('delivery.dashboard.minutesAgo')}`
                        : `${t('delivery.dashboard.minuteAgo')} `
                    }`}
              </span>
              <Button
                type="link btn-invoice-link"
                onClick={refreshDeliveryDetails}
                loading={isDeliveryDetailsRefreshing}
              >
                {t('delivery.dashboard.refresh')}
              </Button>
            </div>
          </Col>
          <Col span={24} className="delivery-search-box">
            <Input placeholder={t('delivery.dashboard.searchLocation')} onChange={onSearch} allowClear />
          </Col>
        </Row>
        <CustomerList customersWithDeliveries={customersWithDeliveries} selectNewCustomer={selectNewCustomer} />
      </React.Fragment>
    );
  return <div></div>;
}

export default DashboardPageComponent;
