import React from 'react';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import AppLoader from 'components/AppLoader';
import ProductView from 'components/ProductView';
import InvoicesExpansion from './InvoicesExpansion';

const ExtendedInvoices = ({
  esyscoUserType,
  extendedInvoicesDetails,
  extendedInvoicesFetching,
  showProductInfo,
  closeProductView,
  onClickSelectedInvoices,
  productInfo,
  showProduct,
  activeExpandedInvoices,
  onClickFetchInvoicePdf,
  invoicePdfFetching
}) => {
  const { invoices, totalCases, totalSplits, outOfStockItemCount, substitutedItemsCount } =
    extendedInvoicesDetails || {};
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {extendedInvoicesFetching ? (
        <AppLoader />
      ) : (
        <React.Fragment>
          {showProduct && productInfo?.productDetails?.productId ? (
            <ProductView
              esyscoUserType={esyscoUserType}
              productInfo={productInfo}
              closeProductView={closeProductView}
            />
          ) : (
            <React.Fragment>
              <Col span={10} className="delivery-invoice">
                <div>
                  <Col span={18}>
                    {invoices?.length ? (
                      <h1 className="delivery-h1">{t('delivery.deliveries.totalItems')}</h1>
                    ) : (
                      <React.Fragment>
                        {invoices ? (
                          <h1 className="delivery-h1">{t('delivery.deliveries.noInvoice')}</h1>
                        ) : (
                          <h1 className="delivery-h1">{t('delivery.deliveries.failInvoice')}</h1>
                        )}
                      </React.Fragment>
                    )}
                  </Col>
                  <Col span={6}>
                    <h1 className="delivery-item-right delivery-total-item-count">
                      {totalCases ? `${totalCases} CS` : ''}
                      {totalCases && totalSplits ? ', ' : ''}
                      {totalSplits ? `${totalSplits} EA` : ''}
                    </h1>
                  </Col>
                  {outOfStockItemCount > 0 && (
                    <>
                      <Col span={18}>
                        <h2 className="delivery-h1">{t('delivery.invoices.itemCount.outOfStock')}</h2>
                      </Col>
                      <Col span={6}>
                        <h2 className="delivery-item-right">
                          {`${outOfStockItemCount} `}
                          {outOfStockItemCount > 1
                            ? t('delivery.invoices.itemCategory.items')
                            : t('delivery.invoices.itemCategory.item')}
                        </h2>
                      </Col>
                    </>
                  )}
                  {substitutedItemsCount > 0 && (
                    <>
                      <Col span={18}>
                        <h2 className="delivery-h1">{t('delivery.invoices.itemCount.subItems')}</h2>
                      </Col>
                      <Col span={6}>
                        <h2 className="delivery-item-right">
                          {`${substitutedItemsCount} `}
                          {substitutedItemsCount > 1
                            ? t('delivery.invoices.itemCategory.items')
                            : t('delivery.invoices.itemCategory.item')}
                        </h2>
                      </Col>
                    </>
                  )}

                  <Col span={24}>
                    <hr className="devider" />
                  </Col>
                  <Col span={24}>
                    {extendedInvoicesDetails && (
                      <InvoicesExpansion
                        esyscoUserType={esyscoUserType}
                        extendedInvoicesDetails={invoices}
                        showProductInfo={showProductInfo}
                        onClickSelectedInvoices={onClickSelectedInvoices}
                        activeExpandedInvoices={activeExpandedInvoices}
                        onClickFetchInvoicePdf={onClickFetchInvoicePdf}
                        invoicePdfFetching={invoicePdfFetching}
                      />
                    )}
                  </Col>
                </div>
              </Col>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ExtendedInvoices;
