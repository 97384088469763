import React from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import DeliveryNotificationsComponent from './DeliveryNotificationsComponent';
import DeliveryListComponent from './DeliveryListComponent';

function NotificationsPageComponent({
  isNotificationsFetching,
  notifications,
  onDeliverySelected,
  selectedDelivery,
  timeSinceLastUpdate,
  refreshNotifications,
  isNotificationsRefreshing,
  customerName
}) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="section-1">
        <Col span={14} className="">
          <Row className="invoice-left-section">
            <Col span={12} className="invoice-wrap">
              <h1>{t('delivery.notifications.title')}</h1>
              <span className="verticle-divider "></span>
              <p className="invoice-period">{t('delivery.notifications.timePeriod')}</p>
            </Col>
            {!isNotificationsFetching && (
              <Col span={12}>
                <div className="last-update">
                  <span className="last-update-text">
                    {timeSinceLastUpdate < 60
                      ? `${t('delivery.dashboard.lastUpdated')} ${timeSinceLastUpdate} ${t(
                          'delivery.dashboard.secondsAgo'
                        )}`
                      : `${t('delivery.dashboard.lastUpdated')} ${Math.floor(timeSinceLastUpdate / 60)} ${
                          Math.floor(timeSinceLastUpdate / 60) > 1
                            ? `${t('delivery.dashboard.minutesAgo')}`
                            : `${t('delivery.dashboard.minuteAgo')} `
                        }`}
                  </span>
                  <Button
                    type="link"
                    className="btn-invoice-link"
                    onClick={refreshNotifications}
                    loading={isNotificationsRefreshing}
                  >
                    {t('delivery.dashboard.refresh')}
                  </Button>
                </div>
              </Col>
            )}
            <Col span={24} className="invoice-wrap invoice-table">
              <DeliveryListComponent
                notifications={notifications}
                onDeliverySelected={onDeliverySelected}
                selectedDeliveryJobId={selectedDelivery?.jobId}
                isNotificationsFetching={isNotificationsFetching}
              />
            </Col>
          </Row>
        </Col>
        {selectedDelivery && (
          <DeliveryNotificationsComponent selectedDelivery={selectedDelivery} customerName={customerName} />
        )}
      </Row>
    </React.Fragment>
  );
}

export default NotificationsPageComponent;
