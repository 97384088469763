import React from 'react';
import { Row, Col, Button } from 'antd';
import { startCase, toLower } from 'lodash';
import { useTranslation } from 'react-i18next';

import ItemPricePlaceHolder from 'components/ItemPricePlaceholder';
import { formattedTotalAmount, isPartialCategory } from 'util/CategoryFormatting';

const GeneralCategory = ({ category, categoryDetails, esyscoUserType, showProductInfo }) => {
  const { totalAmount, lineItems } = categoryDetails;
  const { t } = useTranslation();

  return (
    <>
      <Row className="delivery-invoice-item-heading-wrap">
        <Col span={18} className="delivery-invoice-item-heading">
          <h4 className="delivery-invoice-item-category-heading">{t(`delivery.invoices.itemCategory.${category}`)}</h4>
        </Col>
        <Col span={6} className="delivery-invoice-item-price">
          <h4 className="">{formattedTotalAmount(category, esyscoUserType, totalAmount)}</h4>
        </Col>
      </Row>
      {lineItems.map(
        ({
          itemNumber,
          originalPrice,
          originalOrderQty,
          orderQty,
          itemAmount,
          unitOfMeasure,
          splitCode,
          productDetails
        } = {}) => {
          const isPartial = isPartialCategory(originalOrderQty, orderQty);
          return (
            <Button
              className="btn-invoice-item-link"
              type="link"
              block
              onClick={() =>
                showProductInfo(itemNumber, {
                  isPartial,
                  originalPrice,
                  originalOrderQty,
                  orderQty,
                  itemAmount,
                  unitOfMeasure,
                  splitCode,
                  productDetails
                })
              }
            >
              <Row className="delivery-invoice-items">
                <Col span={18}>
                  <h4 className="delivery-h1-title">
                    {productDetails?.description.match(/[a-z0-9]/i)
                      ? startCase(toLower(productDetails?.description))
                      : itemNumber}
                  </h4>
                </Col>
                <Col span={6}>
                  <ItemPricePlaceHolder
                    category={category}
                    orderQty={orderQty}
                    originalOrderQty={originalOrderQty}
                    unitOfMeasure={unitOfMeasure}
                    isPartial={isPartial}
                  />
                </Col>
              </Row>
            </Button>
          );
        }
      )}
    </>
  );
};

export default GeneralCategory;
