import React from 'react';
import { Table, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { INVOICE_STATUS } from 'shared/constants';
import AppLoader from 'components/AppLoader';
import SelectedInvoice from './SelectedInvoice';

const InvoicesList = ({
  customerDetails,
  esyscoUserType,
  orderByInvoiceStatusCode,
  selectedInvoice,
  selectedInvoiceFetching,
  productFetching,
  productDetails,
  invoicePdfFetching,
  onClickFetchInvoicePdf,
  showProductInfo,
  closeProductView,
  productInfo,
  showProduct,
  selectedInvoiceStatus,
  invoiceItems,
  onSelectedInvoice,
  setRowClassName
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: `${t('delivery.invoice.invoiceID')}`,
      dataIndex: 'invoiceNum',
      key: 'invoiceNum',
      render: text => <span className="invoice-table-title">{text}</span>
    },
    {
      title: `${t('delivery.invoice.invoiceDate')}`,
      dataIndex: 'invoiceDate',
      key: 'invoiceDate'
    },
    {
      title: `${t('delivery.invoice.state')}`,
      dataIndex: 'statusCode',
      key: 'statusCode',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render(value) {
        return t(`delivery.invoices.statusCode.${value}`);
      },
      sorter: (a, b) => {
        const aStatus = orderByInvoiceStatusCode(a.statusCode);
        const bStatus = orderByInvoiceStatusCode(b.statusCode);

        return aStatus - bStatus;
      }
    },
    {
      title: `${t('delivery.invoice.dueDate')}`,
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (checked, record) => {
        if (record.statusCode === INVOICE_STATUS.PAID) return '';
        return record.dueDate;
      }
    }
  ];

  const selectedInvoiceProps = {
    customerDetails,
    esyscoUserType,
    invoiceDetails: { ...selectedInvoice, statusCode: selectedInvoiceStatus },
    productFetching,
    productDetails,
    invoicePdfFetching,
    onClickFetchInvoicePdf,
    showProductInfo,
    closeProductView,
    productInfo,
    showProduct
  };

  return (
    <React.Fragment>
      <Row className="section-1">
        <Col span={14} className="">
          <Row className="invoice-left-section">
            <Col span={24} className="invoice-wrap">
              <h1>{t('delivery.dashboard.invoices')}</h1>
              <span className="verticle-divider "></span>
              <p className="invoice-period">{t('delivery.invoice.threeMonths')}</p>
            </Col>
            <Col span={24} className="invoice-wrap invoice-table">
              <Table
                pagination={{ pageSize: 19 }}
                columns={
                  esyscoUserType
                    ? columns
                    : columns.filter(col => col.dataIndex !== 'statusCode' && col.dataIndex !== 'dueDate')
                }
                dataSource={invoiceItems}
                onRow={onSelectedInvoice}
                rowClassName={setRowClassName}
              />
            </Col>
          </Row>
        </Col>
        {selectedInvoiceFetching ? (
          <AppLoader show />
        ) : (
          selectedInvoice?.invoiceNumber && <SelectedInvoice {...selectedInvoiceProps} />
        )}
      </Row>
    </React.Fragment>
  );
};

export default InvoicesList;
